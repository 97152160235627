<template>
  <div class="decorationRecordList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button
          permission="set"
          text="装修设置"
          @click="settingClick"
        ></v-button>
        <v-button
          permission="add"
          text="新增装修登记"
          @click="create"
        ></v-button>
      </template>
      <template #searchSlot>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-select
          label="资料状态"
          v-model="searchParams.status"
          :options="statusOps"
        ></v-select>
        <v-datepicker
          label="预计装修时间"
          :startTime.sync="searchParams.beginDate"
          :endTime.sync="searchParams.endDate"
        ></v-datepicker>
        <v-select
          label="垃圾清运方式"
          v-model="searchParams.clearMode"
          :options="clearModeOps"
        ></v-select>
        <v-select2
          label="所属公司"
          :width="width"
          v-model="searchParams.regionId"
          v-bind="tenantParams"
          @onChange="onChange"
        ></v-select2>
        <v-select
          label="装修阶段"
          :disabled="
            !(searchParams.regionId && searchParams.regionId.length > 0)
          "
          v-model="searchParams.stageId"
          :options="decorationStageOps"
        ></v-select>
        <v-input label="苑" v-model="searchParams.blockName"></v-input>
        <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元" v-model="searchParams.unit"></v-input>
        <v-input label="室" v-model="searchParams.room"></v-input>
        <v-input
          label="最后编辑人"
          :width="width"
          v-model="searchParams.userName"
        ></v-input>
        <v-datepicker
          label="最后编辑时间"
          :startTime.sync="searchParams.updateTime"
          :endTime.sync="searchParams.updateTime2"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          v-show="scope.row.checkStatus != 2"
          permission="update"
          text="修改装修状态"
          type="text"
          @click="edit(scope.row)"
        ></v-button>

        <v-button
          v-show="scope.row.checkStatus == 2"
          permission="audio"
          text="审核"
          type="text"
          @click="edit(scope.row)"
        ></v-button>
        <!-- <el-button type="text" @click="settingClick(scope.row)"
          >装修设置</el-button
        > -->
      </template>
    </list>
    <!-- 装修设置弹窗 -->
    <el-dialog
      title="查验设置"
      :visible.sync="settingShow"
      label-width="0"
      width="768px"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic"
      >
        <el-form-item>
          <div style="float: left; margin-left: 10%">
            <span style="margin-right: 10px">查验开关</span>
            <el-switch
              v-model="dynamicValidateForm.status"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
            <div>查验项</div>
          </div>
        </el-form-item>

        <el-form-item
          v-for="(domain, index) in dynamicValidateForm.updateFS"
          :key="domain.key"
          :prop="'updateFS.' + index + '.value'"
        >
          <el-input
            :disabled="dynamicValidateForm.status === 1"
            maxlength="30"
            v-model.trim="domain.examineItem"
            style="width: 50%; margin-right: 20px"
          ></el-input>
          <el-button
            type="danger"
            @click.prevent="removeDomain(domain)"
            :disabled="dynamicValidateForm.updateFS.length === 1"
            >删除</el-button
          >
          <el-button type="primary" @click="addDomain">新增</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="settingShow = false">取 消</el-button>
          <el-button type="primary" @click="submitForm(dynamicValidateForm)"
            >保存</el-button
          >
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="settingShow = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保 存</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getDecorationStageURL,
  getExamineURL,
  putExamineURL
} from './api'
import {
  statusOps,
  statusMap,
  clearModeOps,
  clearModeMap,
  checkStatusMap
} from './map'
import { mapHelper } from 'common/utils'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: 'DecorationRecordList',
  data () {
    return {
      dynamicValidateForm: {
        updateFS: [{
          examineItem: ''
        }],
        status: 0
      },
      settingShow: false, // 装修设置弹窗
      width: 180,
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps,
      clearModeOps,
      decorationStageOps: [],
      communityParams,
      tenantParams,
      searchParams: {
        communityId: '',
        status: undefined,
        beginDate: '',
        endDate: '',
        clearMode: undefined,
        regionId: '',
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        userName: '',
        updateTime: '',
        updateTime2: '',
        stageId: undefined
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'companyName',
          label: '所属公司'
        },
        {
          prop: 'address',
          label: '房号信息'
        },
        {
          prop: 'statusText',
          label: '资料状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'realTimeText',
          label: '实际装修时间',
          formatter (row) {
            let showTimeArr = []
            if (row.realBeginDate && row.realBeginDate.length > 0) {
              showTimeArr.push(row.realBeginDate)
            }
            if (row.realEndDate && row.realEndDate.length > 0) {
              showTimeArr.push(row.realEndDate)
            } else {
              if (showTimeArr.length > 0) {
                showTimeArr.push('至今')
              }
            }
            return showTimeArr.join('~')
          }
        },
        {
          prop: 'decorationStageName',
          label: '装修阶段'
        },
        {
          prop: 'timeText',
          label: '预计装修时间',
          formatter (row) {
            return `${row.beginDate || ''} ~ ${row.endDate || ''}`
          }
        },
        {
          prop: 'clearModeText',
          label: '垃圾清运方式',
          formatter (row) {
            return clearModeMap[row.trashClearMode]
          }
        },
        {
          prop: 'updateUser',
          label: '最后编辑人'
        },
        {
          prop: 'updateTime',
          label: '最后编辑时间'
        },
        {
          prop: 'checkStatus',
          label: '审核状态',
          formatter (row) {
            if (row.checkStatus == 2) {
              return '待审核'
            } else {
              return checkStatusMap[row.checkStatus]
            }

          }
        }
      ]
    }
  },

  created () {

  },
  methods: {
    // 保存
    async submitForm (formName) {
      let data = {
        updateFS: [],
        status: formName.status
      }
      formName.updateFS.map((item) => {
        if (item.examineItem) {
          return data.updateFS.push({ examineItem: item.examineItem, id: item.id })
        }
      })
      await this.$axios.post(putExamineURL, formName)
      this.settingShow = false
      // console.log(data, '保存--------------');
    },
    // 重置
    // resetForm (formName) {
    // this.$refs[formName].resetFields();
    // },
    // 删除查验项
    removeDomain (item) {
      var index = this.dynamicValidateForm.updateFS.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.updateFS.splice(index, 1)
      }
    },
    // 新增查验项
    addDomain () {
      this.dynamicValidateForm.updateFS.push({
        id: '',
      });
    },
    // 装修设置弹窗    communityId:row.communityId
    async settingClick () {
      this.settingShow = true
      const res = await this.$axios.get(getExamineURL);
      if (res.data) {
        this.dynamicValidateForm.updateFS = res.data
        this.dynamicValidateForm.status = res.data[0].status
      }
      console.log(this.dynamicValidateForm, '装修查验项设置============================');

    },
    create () {
      this.$router.push({
        name: 'decorationRecordForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'decorationRecordForm',
        query: {
          id: row.id
        }
      })
    },

    onChange (val, echo) {
      if (val) {
        if (!echo) {
          this.searchParams.decorationStageId = undefined
        }
        this.getDecorationStage()
      } else {
        this.searchParams.decorationStageId = undefined
        this.decorationStageOps = []
      }
    },

    async getDecorationStage () {
      let params = {
        type: 3,
        regionId: this.searchParams.regionId
      }
      const { data, status } = await this.$axios.get(getDecorationStageURL, { params })
      if (status === 100) {
        let list = data || []
        let tempList = list.map(item => {
          return {
            text: item.name,
            value: item.categoryId
          }
        }) || []
        const { setOps: stageOps } = mapHelper.setMap(tempList)
        this.decorationStageOps = stageOps(1)
      }
    }

  }
}
</script>
<style scoped lang="scss">
.dialog-footer {
  text-align: center;
}
</style>
