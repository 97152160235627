// 获取列表
// const getListURL = `${API_CONFIG.baseURL}smDecorationAction!list.action`
// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}decoration/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}decoration/export/info`
// 获取房主列表
const getStoreRoomUserListURL = `${API_CONFIG.baseURL}serverCodewordAction!getStoreRoomUserList.action`
// 基础信息保存
const uploadDecorationBaseURL = `${API_CONFIG.butlerBaseURL}decoration/base`
// 施工人员保存
const uploadDecorationStaffURL = `${API_CONFIG.butlerBaseURL}decoration/staff/`
// 施工单位保存
const uploadDecorationConstructionURL = `${API_CONFIG.butlerBaseURL}decoration/construction`
// 申请资料保存
const uploadDecorationAnnexURL = `${API_CONFIG.butlerBaseURL}decoration/annex`
// 上传接口
const uploadUrl = `${API_CONFIG.uploadURL}?module=decoration`
// 修改时间
const editDateUrl = `${API_CONFIG.butlerBaseURL}decoration/code`

// 获取装修阶段 APP2-1309 装修记录增加装修阶段搜索APP2-1336 前端 - 装修记录增加装修阶段搜索
const getDecorationStageURL = `${API_CONFIG.butlerBaseURL}inspect/category/second`

// 获取查验项列表
const getExamineURL = `${API_CONFIG.butlerBaseURL}decoration/items`

// 新增和修改查验项
const putExamineURL = `${API_CONFIG.butlerBaseURL}decoration/examineItems`

// 查询 现场查验
const getSettingBase = `${API_CONFIG.butlerBaseURL}decoration/base/`
// 现场查验结果变更
const putExamineUser = `${API_CONFIG.butlerBaseURL}decoration/examine`

// 查验人
const getUserListURL = `${API_CONFIG.butlerBaseURL}user/search/community/username`
const getInitCodeWordList = `${API_CONFIG.butlerBaseURL}getInitCodeWordList`
export {
  getListURL,
  exportListURL,
  getStoreRoomUserListURL,
  uploadDecorationBaseURL,
  uploadDecorationStaffURL,
  uploadDecorationConstructionURL,
  uploadDecorationAnnexURL,
  uploadUrl,
  editDateUrl,
  getDecorationStageURL,
  getExamineURL,
  putExamineURL,
  getSettingBase,
  putExamineUser,
  getUserListURL,
  getInitCodeWordList
}
