var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "decorationRecordList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportMethod: "new",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { permission: "set", text: "装修设置" },
                  on: { click: _vm.settingClick },
                }),
                _c("v-button", {
                  attrs: { permission: "add", text: "新增装修登记" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "资料状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "预计装修时间",
                    startTime: _vm.searchParams.beginDate,
                    endTime: _vm.searchParams.endDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginDate", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginDate", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                  },
                }),
                _c("v-select", {
                  attrs: { label: "垃圾清运方式", options: _vm.clearModeOps },
                  model: {
                    value: _vm.searchParams.clearMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "clearMode", $$v)
                    },
                    expression: "searchParams.clearMode",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司", width: _vm.width },
                      on: { onChange: _vm.onChange },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.tenantParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: {
                    label: "装修阶段",
                    disabled: !(
                      _vm.searchParams.regionId &&
                      _vm.searchParams.regionId.length > 0
                    ),
                    options: _vm.decorationStageOps,
                  },
                  model: {
                    value: _vm.searchParams.stageId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "stageId", $$v)
                    },
                    expression: "searchParams.stageId",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "苑" },
                  model: {
                    value: _vm.searchParams.blockName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "blockName", $$v)
                    },
                    expression: "searchParams.blockName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "幢" },
                  model: {
                    value: _vm.searchParams.buildingNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "buildingNumber", $$v)
                    },
                    expression: "searchParams.buildingNumber",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "单元" },
                  model: {
                    value: _vm.searchParams.unit,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "unit", $$v)
                    },
                    expression: "searchParams.unit",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "室" },
                  model: {
                    value: _vm.searchParams.room,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "room", $$v)
                    },
                    expression: "searchParams.room",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "最后编辑人", width: _vm.width },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "最后编辑时间",
                    startTime: _vm.searchParams.updateTime,
                    endTime: _vm.searchParams.updateTime2,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "updateTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "updateTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "updateTime2", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "updateTime2", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: scope.row.checkStatus != 2,
                      expression: "scope.row.checkStatus != 2",
                    },
                  ],
                  attrs: {
                    permission: "update",
                    text: "修改装修状态",
                    type: "text",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: scope.row.checkStatus == 2,
                      expression: "scope.row.checkStatus == 2",
                    },
                  ],
                  attrs: { permission: "audio", text: "审核", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查验设置",
            visible: _vm.settingShow,
            "label-width": "0",
            width: "768px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.settingShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm },
            },
            [
              _c("el-form-item", [
                _c(
                  "div",
                  { staticStyle: { float: "left", "margin-left": "10%" } },
                  [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v("查验开关"),
                    ]),
                    _c("el-switch", {
                      attrs: { "active-value": 0, "inactive-value": 1 },
                      model: {
                        value: _vm.dynamicValidateForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.dynamicValidateForm, "status", $$v)
                        },
                        expression: "dynamicValidateForm.status",
                      },
                    }),
                    _c("div", [_vm._v("查验项")]),
                  ],
                  1
                ),
              ]),
              _vm._l(
                _vm.dynamicValidateForm.updateFS,
                function (domain, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: domain.key,
                      attrs: { prop: "updateFS." + index + ".value" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "50%", "margin-right": "20px" },
                        attrs: {
                          disabled: _vm.dynamicValidateForm.status === 1,
                          maxlength: "30",
                        },
                        model: {
                          value: domain.examineItem,
                          callback: function ($$v) {
                            _vm.$set(
                              domain,
                              "examineItem",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "domain.examineItem",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled:
                              _vm.dynamicValidateForm.updateFS.length === 1,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeDomain(domain)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addDomain },
                        },
                        [_vm._v("新增")]
                      ),
                    ],
                    1
                  )
                }
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.settingShow = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(_vm.dynamicValidateForm)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }