import { generateMapByOpts, mapHelper } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '待完善',
    value: 0
  }, {
    text: '已完善',
    value: 1
  }
]

const statusMap = generateMapByOpts(statusOps)
// 垃圾清运方式
const clearModeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '委托服务中心',
    value: 1
  }, {
    text: '自行清理',
    value: 2
  }
]
const clearModeMap = generateMapByOpts(clearModeOps)
// 审核结果
const checkStatus = [
  {
    text: '审核通过',
    value: 0
  },
  {
    text: '审核不通过',
    value: 1
  },
]

const {
  map: checkStatusMap,
  setOps: checkStatusOps
} = mapHelper.setMap(checkStatus)


// 施工方式
const constructionModeOps = [
  {
    text: '单位施工',
    value: 1
  }, {
    text: '个人施工',
    value: 2
  }
]
export {
  statusOps,
  statusMap,
  clearModeOps,

  checkStatusMap,
  checkStatusOps,

  clearModeMap,
  constructionModeOps
}
